import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";
import { ClipLoader } from "react-spinners";

function EventStatistics() {
  const { eventId } = useParams();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEventStatistics();
  }, [eventId]);

  const fetchEventStatistics = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/event/${eventId}/event-statistics`
      );
      if (response.ok) {
        const data = await response.json();
        setStatistics(data);
      } else {
        console.error("Error fetching event statistics:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching event statistics:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} />
      </div>
    );
  }

  if (!statistics) {
    return <div className="text-center p-6">No statistics available</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Event Statistics</h1>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Team Statistics</h2>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Team Name</th>
              <th className="px-4 py-2 border">Games Played</th>
              <th className="px-4 py-2 border">Games Won</th>
              <th className="px-4 py-2 border">Games Lost</th>
              <th className="px-4 py-2 border">Legs Won</th>
              <th className="px-4 py-2 border">Legs Lost</th>
              <th className="px-4 py-2 border">Points</th>
              <th className="px-4 py-2 border">Average Score</th>
              <th className="px-4 py-2 border">Scores > 100</th>
              <th className="px-4 py-2 border">Highest Score</th>
            </tr>
          </thead>
          <tbody>
            {statistics.teams?.map((team) => (
              <tr key={team.team_id} className="text-center">
                <td className="px-4 py-2 border">{team.Team}</td>
                <td className="px-4 py-2 border">{team["Games Played"]}</td>
                <td className="px-4 py-2 border">{team["Games Won"]}</td>
                <td className="px-4 py-2 border">{team["Games Lost"]}</td>
                <td className="px-4 py-2 border">{team["Legs gewonnen"]}</td>
                <td className="px-4 py-2 border">{team["Legs verloren"]}</td>
                <td className="px-4 py-2 border">{team.Punkte}</td>
                <td className="px-4 py-2 border">{team["Average Score"]}</td>
                <td className="px-4 py-2 border">{team["Scores Above 100"]}</td>
                <td className="px-4 py-2 border">{team["Highest Score"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Player Statistics</h2>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Player Name</th>
              <th className="px-4 py-2 border">Team</th>
              <th className="px-4 py-2 border">Average Score</th>
              <th className="px-4 py-2 border">Scores > 100</th>
              <th className="px-4 py-2 border">Highest Score</th>
            </tr>
          </thead>
          <tbody>
            {statistics.teams?.flatMap((team) =>
              team.Players.map((player) => (
                <tr key={player.player_id} className="text-center">
                  <td className="px-4 py-2 border">{player.name}</td>
                  <td className="px-4 py-2 border">{team.Team}</td>
                  <td className="px-4 py-2 border">{player.average_score}</td>
                  <td className="px-4 py-2 border">
                    {player.scores_above_100}
                  </td>
                  <td className="px-4 py-2 border">{player.highest_score}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EventStatistics;
