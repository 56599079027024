import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { BACKEND_URL } from "../config";
import { useNavigate } from "react-router-dom";
import { MdOutlineVerifiedUser, MdInfoOutline } from "react-icons/md";
import CustomKeyboard from "./CustomKeyboard";
import InputSection from "./InputSection";
import { ClipLoader } from "react-spinners"; // Importiere den Spinner

function GameCalculator() {
  const { gameId } = useParams();
  const location = useLocation();
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scoreInput, setScoreInput] = useState("");
  const [dartCount, setDartCount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [scoreInputSingle, setScoreInputSingle] = useState([null, null, null]);
  const [useSingleInput, setUseSingleInput] = useState(false);
  const [isErrorInput, setIsErrorInput] = useState(false);
  const [isMobile, setIsMobile] = useState(true); // Zustand für Geräteerkennung
  const [showModalWinnerDartCount, setShowModalWinnerDartCount] =
    useState(false);

  const [initialBoardQuery, setInitialBoardQuery] = useState(null);

  const [password, setPassword] = useState(""); // Zustand für das Passwort
  const [isAuthorized, setIsAuthorized] = useState(false); // Zustand für die Autorisierung
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Scrollen deaktivieren
    document.body.style.overflow = "hidden";

    return () => {
      // Scrollen wieder aktivieren, wenn die Komponente entladen wird
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const boardParam = queryParams.get("board");

    if (boardParam) {
      setInitialBoardQuery(boardParam);
    }
    // Geräteeinstellungen prüfen, um zu erkennen, ob es ein mobiles Gerät ist
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android|iPad|iPhone|iPod/.test(userAgent)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    // checkIfMobile();
    fetchGameData();
  }, [gameId]);

  const verifyBoardPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BACKEND_URL}/boards/${gameData.board_id}/verify-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setIsAuthorized(true); // Zugriff gewähren
      } else {
        setErrorMessage("Falsches Passwort!"); // Fehlernachricht setzen
      }
    } catch (error) {
      setErrorMessage("Fehler bei der Passwortüberprüfung.");
    } finally {
      setLoading(false);
    }
  };

  const fetchGameData = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/games/${gameId}/details`);
      if (response.ok) {
        const data = await response.json();

        // Setze das aktuelle Team, wenn es in den Daten vorhanden ist, oder wähle das erste Team.
        const initialTeam = data.current_team || Object.keys(data.teams)[0];

        // Setze die Daten und das initiale Team
        setGameData(data);

        if (data.event_id === 1) {
          setIsAuthorized(true);
        }
      } else {
        console.error(
          "Fehler beim Abrufen der Spieldaten:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spieldaten:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleErrorInput = () => {
    setIsErrorInput(true);
    setTimeout(() => {
      setIsErrorInput(false);
    }, 1000);
  };

  const submitScore = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/games/${gameId}/score`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          team_id: gameData.current_team, // Verwende das aktuelle Team
          score: parseInt(scoreInput, 10), // Konvertiert den Score in eine Ganzzahl
          player_id: gameData.teams[gameData.current_team].current_player.id, // Verwende den aktuellen Spieler des Teams
          dart_count: dartCount || 3,
          player_check_mode:
            gameData.teams[gameData.current_team].current_player.check_mode,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // Lokale Aktualisierung des gameData
        if (result.message === "Überworfen") {
          handleErrorInput();
        }
        if (
          result.legscore_team1 >
            gameData.teams[gameData.current_team].legs_won ||
          result.legscore_team2 > gameData.teams[gameData.current_team].legs_won
        ) {
          fetchGameData();
        } else {
          setGameData((prevData) => ({
            ...prevData,
            teams: {
              ...prevData.teams,
              [prevData.current_team]: {
                ...prevData.teams[prevData.current_team],
                score: result.current_team_score, // Aktualisiere den aktuellen Team-Score
                current_player: {
                  id: result.next_player_id, // Setze den nächsten Spieler
                  name: result.next_player_name,
                  check_mode: result.next_player_check_mode,
                  club_player: result.next_player_club_player,
                },
              },
            },
            current_team: result.next_team_id, // Aktualisiere das aktuelle Team nach dem Wechsel
          }));
        }
        setScoreInput(null); // Leert das Eingabefeld
        setDartCount(0);
        setShowModalWinnerDartCount(false);
      } else {
        console.error("Fehler beim Absenden des Scores:", result);
      }
    } catch (error) {
      console.error("Fehler beim Absenden des Scores:", error);
    }
  };

  const handleScoreSubmit = () => {
    if (
      gameData.teams[gameData.current_team].score - parseInt(scoreInput, 10) ==
      0
    ) {
      setShowModalWinnerDartCount(true);
    } else {
      submitScore();
    }
  };

  const undo_score = async () => {
    const confirmUndo = window.confirm(
      "Möchtest du wirklich den letzten Score rückgängig machen?"
    );

    if (!confirmUndo) {
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/undo_last_score/${gameId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        fetchGameData();
      } else {
        console.error("Fehler beim Rückgängig machen des letzten Scores:");
      }
    } catch (error) {
      console.error("Fehler beim Rückgängig machen des letzten Scores:", error);
    }
  };

  const setThrowOnTeam = async (team) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/games/${gameId}/throw_on_team/${team}`,
        {
          method: "PUT",
        }
      );
      const result = await response.json();
      if (response.ok) {
        fetchGameData();
      } else {
        console.error("Fehler beim Wechseln des Teams:", result);
      }
    } catch (error) {
      console.error("Fehler beim Wechseln des Teams:", error);
    }
  };

  const manualTeamSwitch = async () => {
    const confirmUndo = window.confirm(
      "Möchtest du wirklich das Team tauschen?"
    );

    if (!confirmUndo) {
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/games/${gameId}/nextTeam`, {
        method: "PUT",
      });
      const result = await response.json();
      if (response.ok) {
        // setGameData((prevData) => ({
        //   ...prevData,
        //   current_team: result.current_team_id, // Aktualisiert das Team lokal
        // }));
        fetchGameData();
      } else {
        console.error("Fehler beim Wechseln des Teams:", result);
      }
    } catch (error) {
      console.error("Fehler beim Wechseln des Teams:", error);
    }
  };

  const manualPlayerSwitch = async () => {
    const confirmUndo = window.confirm(
      "Möchtest du wirklich den Spieler tauschen?"
    );

    if (!confirmUndo) {
      return;
    }
    try {
      const response = await fetch(
        `${BACKEND_URL}/games/${gameId}/teams/${gameData.current_team}/nextPlayer`,
        {
          method: "PUT",
        }
      );
      const result = await response.json();
      if (response.ok) {
        // setGameData((prevData) => ({
        //   ...prevData,
        //   teams: {
        //     ...prevData.teams,
        //     [gameData.current_team]: {
        //       ...prevData.teams[gameData.current_team],
        //       current_player: {
        //         id: result.next_player_id,
        //         name: result.next_player_name,
        //         club_player: result.next_player_club_player,
        //       },
        //     },
        //   },
        // }));
        fetchGameData();
      } else {
        console.error("Fehler beim Wechseln des Spielers:", result);
      }
    } catch (error) {
      console.error("Fehler beim Wechseln des Spielers:", error);
    }
  };

  const startGame = async () => {
    const response = await fetch(`${BACKEND_URL}/games/${gameId}/start`, {
      method: "POST",
    });
    const result = await response.json();
    if (response.ok) {
      setGameData((prevData) => ({
        ...prevData,
        status: result.new_status,
        current_team: result.current_team,
        leg_count: 1,
        teams: {
          ...prevData.teams, // Behalte die vorhandenen Teams-Daten bei
          team1: {
            ...prevData.teams.team1, // Behalte die anderen Daten von team1 bei
            current_player: {
              id: result.current_player_team1_id,
              name: result.current_player_team1_name,
              club_player: result.current_player_team1_club_player,
            },
          },
          team2: {
            ...prevData.teams.team2, // Behalte die anderen Daten von team2 bei
            current_player: {
              id: result.current_player_team2_id,
              name: result.current_player_team2_name,
              club_player: result.current_player_team2_club_player,
            },
          },
        },
      }));
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const endGame = async () => {
    const response = await fetch(`${BACKEND_URL}/games/${gameId}/end`, {
      method: "POST",
    });
    const result = await response.json();
    if (response.ok) {
      setGameData((prevData) => ({
        ...prevData,
        status: result.status,
      }));
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const getModeDescriptionString = () => {
    const player = gameData.teams[gameData.current_team].current_player;
    return (
      <div>
        <p>Modus: {gameData.mode_name}</p>
        <p>
          Spieler {player.name} Vereinsspieler:
          {player.club_player ? "Ja" : "Nein"}
        </p>
        <h1 className="text-red-500 font-bold">{player.check_mode}</h1>
      </div>
    );
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Ladeanimation anzeigen, wenn nicht autorisiert
  if (!isAuthorized) {
    return (
      <div className="inset-0 bg-opacity-75 flex justify-center items-center mt-4">
        <div className="bg-white rounded-lg p-6 space-y-4 max-w-md w-full text-center">
          <h2 className="text-2xl font-semibold">{gameData?.board}</h2>
          <h2 className="text-2xl font-semibold">Board-Passwort eingeben</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            maxLength="4"
            placeholder="4-stelliges Passwort"
            className="border rounded-lg p-2 w-full text-center text-lg"
          />
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button
            onClick={verifyBoardPassword}
            disabled={loading}
            className="bg-blue-500 text-white w-full px-4 py-2 rounded mt-4"
          >
            {loading ? <ClipLoader size={20} color="#FFF" /> : "Bestätigen"}
          </button>
          <button
            onClick={() => navigate(-1)}
            disabled={loading}
            className="bg-red-500 text-white w-full px-4 py-2 rounded mt-4"
          >
            Zurück
          </button>
        </div>
      </div>
    );
  }

  if (loading || !gameData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} /> {/* Spinner */}
      </div>
    );
  }

  return (
    <div className=" p-2 w-full bg-gray-100">
      {/* Info Button oben rechts */}
      <button
        onClick={toggleModal}
        className="absolute top-7 right-8 bg-blue-500 text-white p-2 rounded-full shadow-md"
      >
        <MdInfoOutline size={24} />
      </button>

      {/* Modal für allgemeine Informationen */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 space-y-4 max-w-lg w-[95vw] text-center">
            <h2 className="text-2xl font-semibold">Spielinformationen</h2>
            <p>ID: {gameData.game_id}</p>
            <p>Runde: {gameData.round}</p>
            <p>Board: {gameData.board}</p>
            <p>Legs zum Gewinnen: {gameData.legs_to_win}</p>
            <p>
              Leg: {gameData.leg_count} /{" "}
              {gameData.legs_to_win + gameData.legs_to_win - 1}
            </p>
            <p>Modus: {gameData.mode_name}</p>

            <button
              onClick={toggleModal}
              className="bg-yellow-500 text-white w-full px-4 py-2 rounded"
            >
              Schließen
            </button>
            <button
              onClick={() => {
                initialBoardQuery === null
                  ? gameData.event_id == 1
                    ? navigate(`/home/`)
                    : navigate(`/event/${gameData.event_id}/tournamentBracket`)
                  : navigate(
                      `/event/${gameData.event_id}/tournamentBracket?board=${initialBoardQuery}`
                    );
              }}
              className="bg-red-500 text-white w-full px-4 py-2 rounded"
            >
              Zurück
            </button>
          </div>
        </div>
      )}

      {/* Modal für die Eingabe der Finish-Darts */}
      {showModalWinnerDartCount && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 space-y-4 max-w-lg w-full text-center">
            <h2 className="text-2xl font-semibold">Eingabe Finish Darts</h2>
            <div>{getModeDescriptionString()}</div>
            <input
              type="number"
              value={dartCount}
              readOnly
              placeholder="Dart Count"
              className="border rounded-lg p-2 w-full sm:flex-1"
            />
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setDartCount(1)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                1
              </button>
              <button
                onClick={() => setDartCount(2)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                2
              </button>
              <button
                onClick={() => setDartCount(3)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                3
              </button>
            </div>
            <div className="flex gap-3 justify-center">
              <button
                onClick={() => {
                  setShowModalWinnerDartCount(false);
                  setDartCount(0);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Zurück
              </button>
              {dartCount > 0 && (
                <button
                  onClick={() => submitScore()}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  OK
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <h2 className="text-2xl font-semibold mb-4 mt-6 text-center">
        Spiel-Rechner
      </h2>
      <div className="flex flex-row justify-between space-x-4 h-[25vh] mb-3">
        {["team1", "team2"].map((teamKey) => (
          <div
            key={teamKey}
            className={`border rounded-lg p-2 shadow-md flex-1 grid ${
              useSingleInput ? "grid-rows-5" : "grid-rows-4"
            } items-center ${
              gameData.current_team === teamKey ? "bg-green-100" : "bg-white"
            }`}
          >
            {/* Team Name */}
            <div className="flex items-center justify-center">
              <h3 className="text-lg font-semibold text-center mb-1">
                <span style={{ fontSize: "1.0em" }}>
                  {gameData.throw_on_team === teamKey && "•"}
                </span>
                {gameData.teams[teamKey].name}
              </h3>
            </div>

            {/* Current Player */}
            <div className="flex items-center justify-center">
              {gameData.teams[teamKey].players.length > 1 && (
                <p className="text-sm text-center flex items-center mt-0">
                  {gameData.teams[teamKey].current_player.name}
                  {gameData.teams[teamKey].current_player.club_player && (
                    <MdOutlineVerifiedUser className="ml-2" />
                  )}
                </p>
              )}
            </div>

            {/* Score */}
            <div className="flex items-center justify-center">
              <p className="text-5xl font-bold text-center mt-2">
                {gameData.teams[teamKey].score}
              </p>
            </div>

            {/* Calculated Score (if useSingleInput is true) */}
            {useSingleInput && (
              <div className="flex items-center justify-center">
                <p className="text-xl font-bold text-center mt-1">
                  {gameData.current_team === teamKey
                    ? gameData.teams[teamKey].score - scoreInput
                    : gameData.teams[teamKey].score}
                </p>
              </div>
            )}

            {/* Legs Won */}
            <div className="flex items-center justify-center">
              <p className="text-center text-sm mt-1">
                Legs: {gameData.teams[teamKey].legs_won}
              </p>
            </div>
          </div>
        ))}
      </div>

      {gameData.status === "bereit" ? (
        <>
          <button
            onClick={startGame}
            className="bg-green-500 text-white w-full sm:w-auto px-4 py-2 rounded"
          >
            Spiel starten
          </button>
        </>
      ) : !gameData.throw_on_team ? (
        <div className="flex flex-col items-center justify-center mt-10">
          <h2 className="text-center mb-4">Wähle das Anwurfteam</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                setThrowOnTeam("team1");
              }}
              className="bg-green-500 text-white w-full sm:w-auto px-4 py-2 rounded"
            >
              {gameData.teams["team1"].name}
            </button>
            <button
              onClick={() => {
                setThrowOnTeam("team2");
              }}
              className="bg-green-500 text-white w-full sm:w-auto px-4 py-2 rounded"
            >
              {gameData.teams["team2"].name}
            </button>
          </div>
          <h1 className="font-bold mt-10 text-red-600 text-2xl">Ausbullen!</h1>
        </div>
      ) : !gameData.winner_id ? (
        <div className="border rounded-lg p-4 shadow-md bg-white space-y-2 text-center">
          {/* Hier wird die InputSection-Komponente verwendet */}
          <InputSection
            isMobile={isMobile}
            scoreInput={scoreInput}
            setScoreInput={setScoreInput}
            scoreInputSingle={scoreInputSingle}
            setScoreInputSingle={setScoreInputSingle}
            useSingleInput={useSingleInput}
            setUseSingleInput={setUseSingleInput}
            handleScoreSubmit={handleScoreSubmit}
            undo_score={undo_score}
            manualPlayerSwitch={manualPlayerSwitch}
            manualTeamSwitch={manualTeamSwitch}
            isErrorInput={isErrorInput}
            setIsErrorInput={setIsErrorInput}
            handleErrorInput={handleErrorInput}
            currentScore={gameData.teams[gameData.current_team].score}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {gameData.winner_id && (
            <h1>
              SPIEL GEWONNEN: {gameData.teams[gameData.current_team].name}
            </h1>
          )}
          {gameData.status === "beendet" ? (
            <button
              onClick={() => {
                initialBoardQuery === null
                  ? gameData.event_id == 1
                    ? navigate(`/home/`)
                    : navigate(`/event/${gameData.event_id}/tournamentBracket`)
                  : navigate(
                      `/event/${gameData.event_id}/tournamentBracket?board=${initialBoardQuery}`
                    );
              }}
              className="bg-red-500 text-white w-full px-4 py-2 rounded"
            >
              Zurück
            </button>
          ) : (
            <button
              onClick={endGame}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Spiel beenden
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default GameCalculator;
