import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../../config";
import { ClipLoader } from "react-spinners"; // Importiere den Spinner
import TournamentGroups from "../TournamentGroups";

function HomePage({ eventId: propEventId }) {
  const { eventId: paramEventId } = useParams(); // URL-Parameter
  const eventId = propEventId || paramEventId; // Bevorzugt `propEventId`, sonst `paramEventId`  const location = useLocation(); // Zugriff auf die Query-Parameter
  const location = useLocation(); // Zugriff auf die Query-Parameter

  const [games, setGames] = useState([]);
  const [eventName, setEventName] = useState([]);
  const [eventStatus, setEventStatus] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("active");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [visibleGamesCount, setVisibleGamesCount] = useState(10); // Initial 10 Spiele anzeigen
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get("view"); // Liest den "view"-Parameter

  useEffect(() => {
    fetchGames();
    fetchEventName();
    fetchGroups();
    fetchEventStatus();
  }, [eventId]);

  useEffect(() => {
    let socket;
    let reconnecting = false;

    const connectWebSocket = () => {
      const wsUrl = `${WEBSOCKET_URL}/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log(`WebSocket connected for event: ${eventId}`);
        reconnecting = false; // Reset des Reconnect-Flags
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === "reload") {
          fetchEventStatus();
          fetchGames();
        }
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
      };

      socket.onclose = () => {
        if (!reconnecting) {
          console.log("WebSocket connection closed. Reconnecting...");
          reconnecting = true;
          setTimeout(connectWebSocket, 5000); // Reconnect nach 5 Sekunden
        }
      };
    };

    connectWebSocket();

    return () => {
      if (socket && socket.readyState !== WebSocket.CLOSED) {
        socket.close();
      }
    };
  }, [eventId]);

  const fetchGames = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}/games`);
      if (response.ok) {
        const data = await response.json();
        setGames(data.games); // Zugriff auf das `games`-Array im Event-Datenformat
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEventName = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}/name`);
      if (response.ok) {
        const data = await response.json();
        setEventName(data.name);
      } else {
        console.error("Fehler beim Abrufen des Events:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Events:", error);
    }
  };

  const fetchEventStatus = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}/name`);
      if (response.ok) {
        const data = await response.json();
        setEventStatus(data.status);
      } else {
        console.error("Fehler beim Abrufen des Events:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Events:", error);
    }
  };

  const fetchGroups = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/groups`);
    const data = await response.json();
    setGroups(data);
  };

  const handleFilterChange = (e) => {
    setSelectedTeam("");
    setSelectedFilter(e.target.value);
  };

  const handleSingleGameClick = (gameId) => {
    navigate(`/live/game?gameId=${gameId}&liveticker=true`);
  };

  const handleAllGamesClick = () => {
    navigate(`/live/${eventId}/liveGames`);
  };

  const showMoreGames = () => {
    setVisibleGamesCount((prevCount) => prevCount + 10); // Zeige 10 weitere Spiele
  };

  const GameCard = ({ gameProp: game }) => {
    return (
      <button
        onClick={() => handleSingleGameClick(game.id)}
        className={`w-full text-white py-2 rounded ${
          game.status === "aktiv"
            ? "bg-green-400 hover:bg-green-600"
            : game.status === "beendet"
            ? "bg-red-400 hover:bg-orange-600"
            : "bg-blue-400 hover:bg-blue-600"
        }`}
      >
        {game.status}
        <br />
        {game.round.description != "Gruppenphase" &&
          game.round.description + " || "}{" "}
        {game.round.group ? game.round.group + " || " : ""}
        {game.board.description}
        <br />
        <span className={game.winner === game.team1 ? "font-bold" : ""}>
          {game.team1}
        </span>{" "}
        {game.legscore_team1} - {game.legscore_team2}{" "}
        <span className={game.winner === game.team2 ? "font-bold" : ""}>
          {game.team2}
        </span>
      </button>
    );
  };

  const filteredGames = () => {
    let filtered = games;

    // Wenn ein Team ausgewählt wurde, alle Spiele des Teams anzeigen
    if (selectedTeam) {
      return games.filter(
        (game) =>
          game.team1.toLowerCase().includes(selectedTeam.toLowerCase()) ||
          game.team2.toLowerCase().includes(selectedTeam.toLowerCase())
      );
    }

    // Ansonsten nach dem ausgewählten Spiele-Filter filtern
    switch (selectedFilter) {
      case "all": // "Alle Spiele" ausgewählt
        return games;
      case "active": // Nur aktive Spiele
        filtered = filtered.filter((game) => game.status === "aktiv");
        break;
      case "mainround": // Nur Hauptrundenspiele
        filtered = filtered.filter((game) => game.round.stage > 0);
        break;
      default: // Filter nach Gruppe
        filtered = filtered.filter(
          (game) => game.round.group === selectedFilter
        );
        break;
    }

    return filtered;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} />
      </div>
    );
  }

  if (view === "activeGames") {
    const groupsWithGames = groups.map((group) => {
      const activeGame = games.find(
        (game) =>
          game.round.group === group.description && game.status === "aktiv"
      );
      if (activeGame) return activeGame;

      const readyGame = games.find(
        (game) =>
          game.round.group === group.description && game.status === "bereit"
      );
      if (readyGame) return readyGame;

      const nextGame = games.find(
        (game) =>
          game.round.group === group.description && game.status === "next"
      );
      if (nextGame) return nextGame;

      return {
        id: `placeholder-${group.id}`,
        team1: "Kein Spiel",
        team2: "",
        legscore_team1: "",
        legscore_team2: "",
        round: { description: "" },
        group_id: group.id,
      };
    });

    return (
      <div
        className="flex flex-col items-center h-screen overflow-hidden"
        style={{ height: "100vh" }}
      >
        {/* <div className="mt-8 flex justify-center">
          <img
            src={"/assets/Logo_b.svg"}
            alt="Event Logo"
            className="h-36 w-36"
          />
        </div> */}
        <h1 className="text-5xl font-bold my-8 text-white">
          {games[0].round.description}
        </h1>
        <div className="flex flex-col w-full px-2 space-y-4t">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 px-4">
            {groupsWithGames.map((game) => (
              <div
                key={game.id}
                className={`p-2 ${
                  game.status === "aktiv"
                    ? "bg-green-500"
                    : game.status === "bereit"
                    ? "bg-blue-500"
                    : game.status === "next"
                    ? "bg-blue-500"
                    : "bg-gray-400"
                } text-white text-2xl rounded shadow flex flex-col items-center text-center`}
              >
                <p className="font-semibold">
                  {game.team1} {game.team2 && `vs ${game.team2}`}
                </p>
                <p>
                  {game.legscore_team1} - {game.legscore_team2}
                </p>
                <p>{game.round.group}</p>
              </div>
            ))}
          </div>

          {/* Tournament Groups */}
          <TournamentGroups
            selectedGroup={selectedFilter}
            compactView={true}
            disableExpandedView={true}
            eventId={eventId}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-4">
      <div className="flex justify-center">
        <img
          src={"/assets/Logo_b.svg"}
          alt="Event Logo"
          className="h-36 w-36"
        />
      </div>
      <div className="space-y-4">
        <button
          onClick={handleAllGamesClick}
          className=" bg-green-500 text-white p-2 rounded hover:bg-green-600 w-full"
        >
          Alle Live Spiele
        </button>
        <div className="flex flex-row gap-2 max-w-full overflow-hidden">
          <div className="flex flex-row">
            <select
              id="filter"
              value={selectedFilter}
              onChange={handleFilterChange}
              className="px-2 py-1 border rounded"
            >
              <option value="all">Alle Spiele</option>
              <option value="active">Aktive Spiele</option>
              <option value="mainround">Hauptrunde</option>
              {[...new Set(games.map((game) => game.round.group))]
                .filter((group) => group !== "t.b.a.")
                .map((group) => (
                  <option key={group} value={group}>
                    {group}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex flex-row">
            <select
              id="teamFilter"
              value={selectedTeam}
              onChange={(e) => {
                setSelectedTeam(e.target.value);
                setSelectedFilter("all"); // Spiele-Filter auf "Alle Spiele" setzen
              }}
              className="px-2 py-1 border rounded w-full truncate" // Truncate sorgt für Überlauf
            >
              <option value="">Alle Teams</option>
              {[
                ...new Set(
                  games
                    .filter((game) => game.round.stage === 0) // Nur Spiele mit round.stage = 0
                    .flatMap((game) => [game.team1, game.team2])
                ),
              ].map((team) => (
                <option key={team} value={team}>
                  {team}
                </option>
              ))}
            </select>
          </div>
        </div>

        {filteredGames().length > 0 ? (
          <>
            <ul className="space-y-2">
              {filteredGames()
                .slice(0, visibleGamesCount)
                .map((game) => (
                  <li key={game.id}>
                    <GameCard gameProp={game} />
                  </li>
                ))}
            </ul>
            {visibleGamesCount < filteredGames().length && (
              <button
                onClick={showMoreGames}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Weitere Spiele anzeigen
              </button>
            )}
          </>
        ) : (
          <p>Derzeit sind keine Spiele verfügbar.</p>
        )}
      </div>

      <TournamentGroups
        selectedGroup={
          selectedTeam
            ? games.find(
                (game) =>
                  game.team1.toLowerCase() === selectedTeam.toLowerCase() ||
                  game.team2.toLowerCase() === selectedTeam.toLowerCase()
              )?.round.group || selectedFilter
            : selectedFilter
        }
        eventId={eventId}
      />
    </div>
  );
}

export default HomePage;
