import React, { useState, useEffect } from "react";
import { BACKEND_URL } from "../config";
import { useNavigate } from "react-router-dom";

function FastGameForm() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [teamBool, setTeamBool] = useState(false);
  const [mode_id, setMode] = useState(2);
  const [modes, setModes] = useState([]);
  const [legs, setLegs] = useState(1);
  const [teams, setTeams] = useState([
    { name: "", players: [{ name: "", club_player: false }] },
    { name: "", players: [{ name: "", club_player: false }] },
  ]);
  const [gameId, setGameId] = useState();
  const [gameReady, setGameReady] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state

  const handleTeamBoolChange = (e) => setTeamBool(e.target.checked);
  const handleModeChange = (e) =>
    setMode(e.target.value === "" ? "" : parseInt(e.target.value));
  const handleLegsChange = (e) =>
    setLegs(e.target.value === "" ? "" : parseInt(e.target.value));

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  useEffect(() => {
    fetchModes();
  }, []);

  const fetchModes = async () => {
    const response = await fetch(`${BACKEND_URL}/modes/`);
    const data = await response.json();
    setModes(data);
  };

  const handleTeamNameChange = (index, e) => {
    const newTeams = [...teams];
    newTeams[index].name = e.target.value;
    setTeams(newTeams);
  };

  const handleAddPlayer = (teamIndex) => {
    const newTeams = [...teams];
    newTeams[teamIndex].players.push({ name: "", club_player: false });
    setTeams(newTeams);
  };

  const handlePlayerChange = (teamIndex, playerIndex, field, value) => {
    const newTeams = [...teams];
    newTeams[teamIndex].players[playerIndex][field] = value;
    setTeams(newTeams);
  };

  const createFastGame = async (data) => {
    setLoading(true);
    const response = await fetch(`${BACKEND_URL}/fastGame/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      setGameId(responseData.game_id);
      setGameReady(true);
    }
    setLoading(false);
  };

  const startFastGame = async () => {
    const response = await fetch(`${BACKEND_URL}/fastGame/${gameId}/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      navigateToFastGameCalculator(gameId);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedTeams = teams.map((team) => {
      if (!teamBool && team.players.length > 0) {
        return { ...team, name: team.players[0].name };
      }
      return team;
    });

    const formData = {
      teamBool,
      mode_id,
      legs,
      teams: updatedTeams,
    };

    createFastGame(formData);
  };

  const navigateToFastGameCalculator = (gameId) => {
    navigate(`/game/${gameId}/gameCalculator`);
  };

  return (
    <div className="p-4 max-w-lg mx-auto relative">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Schnelles Spiel erstellen
      </h2>

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-gray-100 z-50">
          <div className="spinner border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
        </div>
      )}

      {step === 1 && (
        <div className="border rounded-lg p-4 shadow-md bg-white">
          <h3 className="text-xl font-semibold mb-2">
            Allgemeine Einstellungen
          </h3>
          <label className="block mb-2">
            <span>Teams aktivieren:</span>
            <input
              type="checkbox"
              checked={teamBool}
              onChange={handleTeamBoolChange}
              className="ml-2"
              disabled={gameReady} // Disable if game is ready
            />
          </label>
          <label className="block mb-2">
            <select
              value={mode_id || ""}
              onChange={handleModeChange}
              className="border border-gray-300 p-2 rounded mb-4 w-full"
              disabled={gameReady} // Disable if game is ready
            >
              <option value="">Modus wählen</option>
              {modes.map((mode) => (
                <option key={mode.id} value={mode.id}>
                  {mode.name}
                </option>
              ))}
            </select>
          </label>
          <label className="block mb-2">
            <span>Legs:</span>
            <input
              type="number"
              value={legs || ""}
              onChange={handleLegsChange}
              className="ml-2 border rounded p-1 w-full"
              disabled={gameReady} // Disable if game is ready
            />
          </label>
          <button
            onClick={handleNextStep}
            className="mt-4 bg-blue-500 text-white px-6 py-2 rounded"
            // Disable if game is ready
          >
            Weiter
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="border rounded-lg p-4 shadow-md bg-white">
          <h3 className="text-xl font-semibold mb-2">
            Teams und Spieler hinzufügen
          </h3>
          {teams.map((team, teamIndex) => (
            <div key={teamIndex} className="mb-4">
              <label className="block mb-2">
                {teamBool && (
                  <>
                    <span>Teamname:</span>
                    <input
                      type="text"
                      value={teamBool ? team.name : ""}
                      onChange={(e) => handleTeamNameChange(teamIndex, e)}
                      disabled={!teamBool || gameReady} // Disable if teamBool is false or game is ready
                      placeholder={`Team ${teamIndex + 1}`}
                      className="ml-2 border rounded p-1 w-full"
                    />
                  </>
                )}
              </label>

              {team.players.map((player, playerIndex) => (
                <div key={playerIndex} className="mb-2">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                    <div className="flex items-center space-x-2">
                      <label>Spielername:</label>
                      <input
                        type="text"
                        value={player.name}
                        onChange={(e) =>
                          handlePlayerChange(
                            teamIndex,
                            playerIndex,
                            "name",
                            e.target.value
                          )
                        }
                        className="border rounded p-1 w-full sm:w-auto"
                        disabled={gameReady} // Disable if game is ready
                      />
                    </div>
                    <div className="flex items-center space-x-2 mt-2 sm:mt-0">
                      <label>Vereinsspieler:</label>
                      <input
                        type="checkbox"
                        checked={player.club_player}
                        onChange={(e) =>
                          handlePlayerChange(
                            teamIndex,
                            playerIndex,
                            "club_player",
                            e.target.checked
                          )
                        }
                        className="ml-2"
                        disabled={gameReady} // Disable if game is ready
                      />
                    </div>
                  </div>
                </div>
              ))}

              {teamBool && (
                <button
                  onClick={() => handleAddPlayer(teamIndex)}
                  className="mb-2 bg-green-500 text-white px-4 py-1 rounded"
                  disabled={gameReady} // Disable if game is ready
                >
                  Spieler hinzufügen
                </button>
              )}
            </div>
          ))}

          <button
            onClick={handlePrevStep}
            className="mr-4 bg-gray-500 text-white px-6 py-2 rounded"
            // Disable if game is ready
          >
            Zurück
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-6 py-2 rounded"
            disabled={gameReady} // Disable if game is ready
          >
            Spiel erstellen
          </button>
        </div>
      )}
      {gameReady && (
        <div className="border rounded-lg p-4 shadow-md bg-white text-center mt-4">
          <button
            onClick={startFastGame}
            className="bg-green-500 text-white px-6 py-2 rounded"
          >
            Spiel starten
          </button>
        </div>
      )}
    </div>
  );
}

export default FastGameForm;
