import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../config";
import { ClipLoader } from "react-spinners";

function TournamentGroups({
  eventId: propEventId,
  selectedGroup,
  compactView = false,
  disableExpandedView = false,
}) {
  const { eventId: paramEventId } = useParams(); // URL-Parameter
  const eventId = propEventId || paramEventId;
  const [groupConfig, setGroupConfig] = useState([]);
  const [groupTables, setGroupTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isExpandedView, setIsExpandedView] = useState(false);
  const [activeTeam, setActiveTeam] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    fetchGroupTables();
  }, [eventId]);

  useEffect(() => {
    let socket;
    const connectWebSocket = () => {
      const wsUrl = `${WEBSOCKET_URL}/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log(`WebSocket connected for event: ${eventId}`);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === "reload") {
          fetchGroupTables();
          return;
        }
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [eventId]);

  const fetchGroupTables = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/groupsRanking`
      );
      if (response.ok) {
        const data = await response.json();
        setGroupConfig({
          safeQualPlaces: data.safeQualPlaces,
          extraTeamsNeeded: data.extraTeamsNeeded,
        });
        setGroupTables(data["group_tables"] || []);
      } else {
        console.error(
          "Fehler beim Abrufen der Gruppentabellen:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Gruppentabellen:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRowClass = (team, config) => {
    const { safeQualPlaces, extraTeamsNeeded } = config;

    if (team.Platzierung <= safeQualPlaces && team.Qualifiziert) {
      return "bg-green-200";
    }

    if (
      team.Platzierung === safeQualPlaces + 1 &&
      extraTeamsNeeded > 0 &&
      team.Qualifiziert
    ) {
      return "bg-yellow-200";
    }

    return "";
  };

  const openPopup = (team) => {
    setActiveTeam(team);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setActiveTeam(null);
    setIsPopupVisible(false);
  };

  const filteredGroups =
    selectedGroup === "all" ||
    selectedGroup === "active" ||
    selectedGroup === "mainround"
      ? groupTables
      : groupTables.filter(
          (group) => group.group_description === selectedGroup
        );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} />
      </div>
    );
  }

  if (!groupTables.length) {
    return (
      <div className="text-center p-6">Keine Gruppentabellen verfügbar</div>
    );
  }

  return (
    <div className={compactView ? "p-4" : "p-4"}>
      {!disableExpandedView && (
        <div className="flex  items-center mb-6">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={isExpandedView}
              onChange={() => setIsExpandedView(!isExpandedView)}
            />
            <span
              className={`${compactView ? "text-sm" : "text-lg"} font-medium`}
            >
              Erweiterte Gruppenansicht
            </span>
          </label>
        </div>
      )}

      {!isExpandedView ? (
        <div
          className={`grid ${
            compactView
              ? "grid-cols-1 sm:grid-cols-3 gap-2"
              : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          }`}
        >
          {filteredGroups.map((group) => (
            <div
              key={group.group_id}
              className={`bg-white shadow-lg rounded-lg ${
                compactView ? "p-4" : "p-4"
              } border border-gray-200`}
            >
              <h3
                className={`text-center font-semibold ${
                  compactView ? "text-2xl mb-2" : "text-xl mb-4"
                }`}
              >
                {group.group_description}
              </h3>
              <ul className="space-y-2">
                {group.group_data.map((team, index) => (
                  <li
                    key={index}
                    className={`${
                      compactView ? "p-2 text-xl" : "p-4 text-base"
                    } border rounded-lg hover:bg-gray-100 flex justify-between items-center cursor-pointer ${getRowClass(
                      team,
                      {
                        safeQualPlaces: groupConfig.safeQualPlaces,
                        extraTeamsNeeded: groupConfig.extraTeamsNeeded,
                      }
                    )}`}
                    onClick={() => openPopup(team)}
                  >
                    <span className="font-medium">{team.Team}</span>
                    <span className="font-semibold text-blue-500">
                      {team.Punkte} Pkt
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {filteredGroups.map((group) => (
            <div key={group.group_id} className="mb-6">
              <h3 className="text-xl font-bold mb-4">
                {group.group_description}
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">#</th>
                      <th className="px-4 py-2 border">Team</th>
                      <th className="px-4 py-2 border">Spiele gespielt</th>
                      <th className="px-4 py-2 border">Spiele gewonnen</th>
                      <th className="px-4 py-2 border">Spiele verloren</th>
                      <th className="px-4 py-2 border">Legs Differenz</th>
                      <th className="px-4 py-2 border">Legs gewonnen</th>
                      <th className="px-4 py-2 border">Legs verloren</th>
                      <th className="px-4 py-2 border">Average</th>
                      <th className="px-4 py-2 border">Punkte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.group_data.map((team, index) => (
                      <tr
                        key={index}
                        className={`text-center text-sm ${getRowClass(team, {
                          safeQualPlaces: groupConfig.safeQualPlaces,
                          extraTeamsNeeded: groupConfig.extraTeamsNeeded,
                        })}`}
                      >
                        <td className="px-4 py-2 border">{team.Platzierung}</td>
                        <td className="px-4 py-2 border">{team.Team}</td>
                        <td className="px-4 py-2 border">
                          {team["Spiele gespielt"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Spiele gewonnen"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Spiele verloren"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Legs gewonnen"] - team["Legs verloren"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Legs gewonnen"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Legs verloren"]}
                        </td>
                        <td className="px-4 py-2 border">
                          {team["Average Score"]}
                        </td>
                        <td className="px-4 py-2 border">{team.Punkte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}

      {isPopupVisible && activeTeam && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-8 w-96 relative">
            <button
              onClick={closePopup}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
            >
              ✕
            </button>
            <h2 className="text-3xl font-extrabold mb-6 text-center text-gray-800">
              {activeTeam.Team}
            </h2>
            <ul className="divide-y divide-gray-300">
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Platzierung:
                </span>
                <span>{activeTeam.Platzierung}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Spiele gespielt:
                </span>
                <span>{activeTeam["Spiele gespielt"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Spiele gewonnen:
                </span>
                <span>{activeTeam["Spiele gewonnen"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Spiele verloren:
                </span>
                <span>{activeTeam["Spiele verloren"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Legs gewonnen:
                </span>
                <span>{activeTeam["Legs gewonnen"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Legs verloren:
                </span>
                <span>{activeTeam["Legs verloren"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">
                  Legs Differenz:
                </span>
                <span>
                  {activeTeam["Legs gewonnen"] - activeTeam["Legs verloren"]}
                </span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">Average:</span>
                <span>{activeTeam["Average Score"]}</span>
              </li>
              <li className="py-2 flex justify-between">
                <span className="font-semibold text-gray-700">Punkte:</span>
                <span>{activeTeam.Punkte}</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default TournamentGroups;
