import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../../config";
import {
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/solid";

function GameViewer({
  gameId: propGameId,
  liveticker: propLiveticker,
  board: propBoard,
}) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();

  // Query-Params extrahieren
  const queryGameId = queryParams.get("gameId");
  const queryLiveticker = queryParams.has("liveticker")
    ? queryParams.get("liveticker") === "true"
    : undefined; // Wenn nicht vorhanden, undefined setzen
  const queryBoardId = queryParams.get("boardId") || queryParams.get("boardid");
  const queryLiveViewer = queryParams.get("liveviewer") === "true";

  // Props und Query-Params kombinieren
  const gameId = propGameId || queryGameId;
  const liveticker = propLiveticker ?? queryLiveticker ?? true; // Props haben Vorrang, Standardwert true
  const boardId = propBoard?.id || queryBoardId;

  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);
  const [liveTickerData, setLiveTickerData] = useState([]);
  const [showLiveTicker, setShowLiveTicker] = useState(false);
  const [isGameDataLoaded, setIsGameDataLoaded] = useState(false);
  const [averages, setAverages] = useState({
    team1: null,
    team2: null,
    player1: null,
    player2: null,
  });

  // useEffect(() => {
  //   // Scrollen deaktivieren
  //   document.body.style.overflow = "hidden";

  //   return () => {
  //     // Scrollen wieder aktivieren, wenn die Komponente entladen wird
  //     document.body.style.overflow = "auto";
  //   };
  // }, []);

  useEffect(() => {
    if (boardId) {
      fetchInitialGameDataByBoard();
    } else {
      fetchInitialGameData();
    }
  }, [gameId, boardId, isGameDataLoaded]);

  const fetchInitialGameData = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/games/${gameId}/details`);
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Spieldaten");
      }
      const data = await response.json();
      setGameData(data);
      setIsGameDataLoaded(true);
      // fetchAverages(data);
    } catch (error) {
      console.error("Fehler beim Abrufen der Spieldaten:", error);
      setError("Fehler beim Abrufen der Spieldaten.");
    }
  };

  const fetchInitialGameDataByBoard = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/games/${boardId}/by_board`);
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Spieldaten");
      }
      const data = await response.json();
      setGameData(data);
      setIsGameDataLoaded(true);

      // fetchAverages(data);
    } catch (error) {
      // console.error("Fehler beim Abrufen der Spieldaten:", error);
      setError("Kein aktives oder nächstes Spiel");
    }
  };

  const fetchAverages = async (data) => {
    try {
      const [team1Avg, team2Avg, player1Avg, player2Avg] = await Promise.all([
        fetchAverageTeam(data.event_id, data.teams.team1.id, gameId),
        fetchAverageTeam(data.event_id, data.teams.team2.id, gameId),
        fetchAveragePlayer(
          data.event_id,
          data.teams.team1.current_player.id,
          gameId
        ),
        fetchAveragePlayer(
          data.event_id,
          data.teams.team2.current_player.id,
          gameId
        ),
      ]);

      setAverages({
        team1: team1Avg?.average_score || null,
        team2: team2Avg?.average_score || null,
        player1: player1Avg?.average_score || null,
        player2: player2Avg?.average_score || null,
      });
    } catch (error) {
      console.error("Fehler beim Abrufen der Averages:", error);
    }
  };

  const fetchAverageTeam = async (event_id, team_id, game_id) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/average/team-game/${event_id}/${team_id}/${game_id}`
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen des Team-Averages");
      }
      return await response.json();
    } catch (error) {
      console.error("Fehler beim Abrufen des Team-Averages:", error);
      return null;
    }
  };

  const fetchAveragePlayer = async (event_id, player_id, game_id) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/average/player-game/${event_id}/${player_id}/${game_id}`
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen des Spieler-Averages");
      }
      return await response.json();
    } catch (error) {
      console.error("Fehler beim Abrufen des Spieler-Averages:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!isGameDataLoaded) return; // Warte, bis die initialen Spieldaten geladen sind
    let socket;
    const connectWebSocket = () => {
      const wsUrl = `${WEBSOCKET_URL}/ws/game/${gameData.game_id}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected for game:", gameData.game_id);
        setError(null);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.action === "reload") {
          if (boardId) {
            fetchInitialGameDataByBoard();
          } else {
            fetchInitialGameData();
          }
          if (liveticker) {
            fetchLivetickerGameData();
          }
          return;
        }
        if (data.action === "new_score") {
          if (liveticker) {
            const newScore = {
              score_id: data.new_score_id,
              game_id: gameId,
              leg: data.leg_count,
              player_id: data.current_player_id,
              player_name: data.current_player_name,
              team_id:
                data.current_team_id === "team1"
                  ? gameData.teams.team1.id
                  : gameData.teams.team2.id,
              score: data.score_subtracted,
              created_on: new Date().toISOString(), // Zeitstempel der Nachricht
            };

            // Liveticker aktualisieren
            setLiveTickerData((prevData) => {
              const updatedData = [...prevData, newScore];
              return updatedData.sort(
                (a, b) => new Date(a.created_on) - new Date(b.created_on)
              );
            });
          }

          setGameData((prevData) => {
            const updatedData = {
              ...prevData,
              teams: {
                ...prevData.teams,
                [data.current_team_id]: {
                  ...prevData.teams[data.current_team_id],
                  score: data.current_team_score,
                  current_player: {
                    id: data.next_player_id,
                    name: data.next_player_name,
                    check_mode: data.next_player_check_mode,
                    club_player: data.next_player_club_player,
                  },
                },
              },
              current_team: data.next_team_id,
              leg_count: data.leg_count,
              winner_id: data.winner_id,
            };

            return updatedData;
          });
        }
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
        setError("Verbindungsfehler. Bitte später erneut versuchen.");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
    // }, [gameId, boardId, isGameDataLoaded]);
  }, [gameData?.game_id, isGameDataLoaded]);

  const toggleLiveTicker = () => {
    setShowLiveTicker(!showLiveTicker);
    if (!showLiveTicker) {
      fetchLivetickerGameData();
    }
  };

  const fetchLivetickerGameData = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/all_scores/${gameData?.game_id}`
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Liveticker-Daten");
      }
      const data = await response.json();
      setLiveTickerData(data.scores || []);
    } catch (error) {
      console.error("Fehler beim Abrufen der Liveticker-Daten:", error);
      setError("Fehler beim Abrufen der Liveticker-Daten.");
    }
  };

  // if (error) {
  //   return <div className="text-red-500 text-center">{error}</div>;
  // }

  // if (!gameData) {
  //   return <div className="text-center text-gray-500">Lädt Spieldaten...</div>;
  // }

  // Generate interleaved ticker data
  const interleavedTickerData = [];
  liveTickerData.forEach((score, index) => {
    if (score.team_id === gameData.teams.team1.id) {
      interleavedTickerData.push([score, null]);
    } else {
      interleavedTickerData.push([null, score]);
    }
  });

  return (
    <div className="flex justify-center m-w-full h-full w-full p-2 bg-gray-900 rounded-lg shadow-lg">
      <div className="relative p-4 w-full h-auto text-white  md:p-6 md:text-xl ">
        {/* Fehlerhinweis */}
        {error && (
          <div className="text-red-500 text-center mb-4 md:text-2xl">
            {error}
          </div>
        )}

        {/* Current Score */}
        <div className="flex flex-col justify-center items-center mb-6 text-lg font-bold md:mb-8 md:text-2xl">
          <div>{gameData?.round}</div>
          <div className="mt-2 md:mt-2 md:text-xl">
            {gameData?.board || propBoard?.description || "Kein Board"}
          </div>

          <div className="text-center text-5xl md:text-7xl mt-4">
            {gameData?.teams?.team1.legs_won} -{" "}
            {gameData?.teams?.team2.legs_won}
          </div>
        </div>

        <div className="flex flex-wrap gap-4 justify-center items-stretch md:gap-6">
          {["team1", "team2"].map((teamKey) => (
            <div
              key={teamKey}
              className={`flex-1 px-1 py-2 rounded-lg border-4 min-w-[47%] flex flex-col ${
                gameData?.current_team === teamKey
                  ? "border-green-500"
                  : "border-gray-700"
              } md:px-4 md:py-6`}
            >
              <div className="flex-1 flex items-center justify-center min-h-[3.5rem] md:min-h-[4rem]">
                <h3
                  className={`text-base font-bold text-center uppercase ${
                    gameData?.winner_id === gameData?.teams[teamKey].id &&
                    "text-green-500"
                  } md:text-2xl`}
                >
                  {gameData?.throw_on_team === teamKey && (
                    <span className="font-bold ml-2">•</span>
                  )}
                  {gameData?.teams[teamKey].name}
                </h3>
              </div>
              <div className="flex-1 flex items-center justify-center mt-2 md:mt-4">
                <div className="text-sm text-center md:text-lg">
                  {gameData?.teams[teamKey].current_player.name || "-"}
                </div>
              </div>
              <div className="flex-1 flex items-center justify-center mt-3 md:mt-6">
                <div
                  className={`text-7xl font-bold text-center ${
                    queryLiveViewer ? "md:text-7xl" : "md:text-9xl"
                  }`}
                >
                  {gameData?.teams[teamKey].score}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Liveticker Button */}
        {liveticker && !queryLiveViewer ? (
          <>
            <button
              onClick={toggleLiveTicker}
              className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded w-full sm:w-auto"
            >
              {showLiveTicker ? "Liveticker ausblenden" : "Liveticker anzeigen"}
            </button>

            {!boardId && !queryLiveViewer && (
              <button
                onClick={() =>
                  navigate(-1) || navigate(`/live/event/${gameData.event_id}`)
                }
                className="absolute top-4 left-4 bg-gray-700 hover:bg-gray-600 text-gray-300 p-2 rounded-full shadow-md focus:outline-none"
              >
                <ArrowLeftCircleIcon className="h-6 w-6 text-gray-300" />
              </button>
            )}
          </>
        ) : (
          gameData?.game_id &&
          !queryLiveViewer && (
            <button
              onClick={() =>
                navigate(
                  `/live/game?gameId=${gameData.game_id}&liveticker=true`
                )
              }
              className="absolute top-4 right-4 bg-gray-700 hover:bg-gray-600 text-gray-300 p-2 rounded-full shadow-md focus:outline-none"
            >
              <ArrowRightCircleIcon className="h-6 w-6 text-gray-300" />
            </button>
          )
        )}

        {/* Live Ticker */}
        {/* Live Ticker */}
        {showLiveTicker && (
          <div className="mt-6 bg-gray-800 p-4 rounded-lg shadow-inner">
            <h3 className="text-xl font-semibold mb-4 text-center">
              Liveticker
            </h3>
            {/* Gewinner nur im letzten Leg anzeigen */}
            {gameData.winner_id && (
              <div className="mb-3 text-center">
                <span className="font-bold text-green-500">
                  Gewinner:{" "}
                  {gameData.winner_id === gameData.teams.team1.id
                    ? gameData.teams.team1.name
                    : gameData.teams.team2.name}
                </span>
              </div>
            )}
            <div className="grid grid-cols-3 gap-4">
              {
                liveTickerData
                  .sort(
                    (a, b) => new Date(a.created_on) - new Date(b.created_on)
                  ) // Sortiere nach ältestem zuerst
                  .reduce(
                    (acc, score, index) => {
                      const updatedScores = { ...acc.currentScores };

                      // Ziehe den aktuellen Score ab
                      if (score.team_id === gameData.teams.team1.id) {
                        updatedScores.team1 -= score.score;
                      } else if (score.team_id === gameData.teams.team2.id) {
                        updatedScores.team2 -= score.score;
                      }

                      // Score-Einträge hinzufügen
                      acc.result.push(
                        <React.Fragment key={score.score_id}>
                          {/* Team 1 Score */}
                          <div>
                            {score.team_id === gameData.teams.team1.id ? (
                              <div className="mb-2">
                                <span className="font-semibold">
                                  {score.player_name}: {score.score}
                                </span>
                              </div>
                            ) : (
                              <div className="mb-2 text-gray-400">—</div> // Leerplatz
                            )}
                          </div>

                          {/* Aktueller Scorestand */}
                          <div className="text-center text-gray-200 font-semibold">
                            {updatedScores.team1} - {updatedScores.team2}
                          </div>

                          {/* Team 2 Score */}
                          <div>
                            {score.team_id === gameData.teams.team2.id ? (
                              <div className="mb-2">
                                <span className="font-semibold">
                                  {score.player_name}: {score.score}
                                </span>
                              </div>
                            ) : (
                              <div className="mb-2 text-gray-400">—</div> // Leerplatz
                            )}
                          </div>
                        </React.Fragment>
                      );

                      // Prüfen, ob das Leg gewonnen wurde
                      const isLegWon =
                        updatedScores.team1 === 0 || updatedScores.team2 === 0;

                      if (isLegWon) {
                        // Speichere den aktuellen Leg-Stand
                        const legStand = {
                          team1Legs: acc.legScores.team1Legs,
                          team2Legs: acc.legScores.team2Legs,
                        };

                        if (updatedScores.team1 === 0) {
                          legStand.team1Legs += 1;
                        } else {
                          legStand.team2Legs += 1;
                        }

                        // Linie und Informationen für das gewonnene Leg hinzufügen NACH dem aktuellen Score
                        acc.result.push(
                          <div
                            key={`leg-line-${index}`}
                            className="col-span-3 border-b-2 border-dashed border-gray-600 text-center mb-2 pb-2"
                          >
                            <div className="flex flex-col">
                              <span>
                                {acc.previousLeg}. Leg beendet - Stand:{" "}
                                {legStand.team1Legs} - {legStand.team2Legs}
                              </span>
                              <span>
                                {updatedScores.team1 === 0
                                  ? `${gameData.teams.team1.name} hat das Leg gewonnen`
                                  : `${gameData.teams.team2.name} hat das Leg gewonnen`}
                              </span>
                            </div>
                          </div>
                        );

                        // Aktualisiere die gespeicherten Leg-Stände
                        acc.legScores = legStand;

                        // Setze die Punktestände für das nächste Leg zurück
                        acc.currentScores = { team1: 501, team2: 501 };
                        acc.previousLeg += 1; // Gehe zum nächsten Leg
                      } else {
                        acc.currentScores = updatedScores; // Speichere die aktualisierten Punktestände
                      }

                      return acc;
                    },
                    {
                      result: [],
                      currentScores: { team1: 501, team2: 501 }, // Startpunkt
                      legScores: { team1Legs: 0, team2Legs: 0 }, // Start-Leg-Stände
                      previousLeg: liveTickerData[0]?.leg || 1, // Beginne mit dem ersten Leg
                    }
                  )
                  .result.reverse() /* Umkehren der Anzeige (absteigend) */
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GameViewer;
