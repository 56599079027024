import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../../config";
import GameViewer from "./GameViewer";
import { ClipLoader } from "react-spinners"; // Importiere den Spinner

function LiveGames() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryLiveViewer = queryParams.get("liveviewer") === "true";

  useEffect(() => {
    fetchGames();
    fetchBoards();
  }, [eventId]);

  const fetchGames = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/live_games`
      );
      if (response.ok) {
        const data = await response.json();
        setGames(data.games);
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBoards = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/current_round/boards`
      );
      if (response.ok) {
        const data = await response.json();
        setBoards(data);
      } else {
        console.error("Fehler beim Abrufen der Boards:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Boards:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let socket;
    const connectWebSocket = () => {
      const wsUrl = `${WEBSOCKET_URL}/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected to:", wsUrl);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === "reload") {
          fetchGames();
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected. Attempting to reconnect...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [eventId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} /> {/* Spinner */}
      </div>
    );
  }
  const sortedGames = games.sort((a, b) => a.board.id - b.board.id);

  return (
    <div className="flex flex-col h-screen overflow-hidde">
      <div className="flex-grow p-4 overflow-y-auto">
        {!queryLiveViewer && (
          <button
            className="bg-red-500 text-white w-full px-4 py-2 rounded mb-2"
            onClick={() => navigate(`/live/event/${eventId}`)}
          >
            Zurück
          </button>
        )}

        {games.length > 0 ? (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 auto-rows-fr">
            {boards?.map((board) => (
              <div
                key={board.id}
                className="flex items-center justify-center rounded-lg w-full h-full"
              >
                <GameViewer liveticker={false} board={board} />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">
            Derzeit sind keine Spiele verfügbar.
          </p>
        )}
      </div>
    </div>
  );
}

export default LiveGames;
