import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../../config";

const LiveScoreboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameId = queryParams.get("gameId") || queryParams.get("gameid");
  const boardId = queryParams.get("boardId") || queryParams.get("boardid");

  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);

  const gameSocketRef = useRef(null);
  const boardSocketRef = useRef(null);

  const fetchGameData = async () => {
    try {
      if (gameId) {
        const response = await fetch(`${BACKEND_URL}/games/${gameId}/details`);
        if (!response.ok)
          throw new Error("Fehler beim Abrufen der Spieldaten.");
        const data = await response.json();
        setGameData(data);
      } else if (boardId) {
        const response = await fetch(
          `${BACKEND_URL}/boards/${boardId}/current_live_game`
        );
        if (!response.ok) throw new Error("Kein aktives Spiel gefunden.");
        const data = await response.json();

        const gameResponse = await fetch(
          `${BACKEND_URL}/games/${data.id}/details`
        );
        if (!gameResponse.ok)
          throw new Error("Fehler beim Abrufen der Spieldaten.");
        const gameData = await gameResponse.json();
        setGameData(gameData);
      }
    } catch (err) {
      console.error(err);
      setError(err.message || "Fehler beim Laden der Spieldaten.");
      setGameData(null); // Kein Spiel verfügbar
    }
  };

  const connectGameWebSocket = (gameId) => {
    if (gameSocketRef.current) {
      gameSocketRef.current.close();
    }

    const wsUrl = `${WEBSOCKET_URL}/ws/game/${gameId}`;
    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("WebSocket connected for game:", gameId);
      setError(null);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.action === "reload" || data.action == "new_score") {
        fetchGameData();
      } else if (data.action === "Spiel erfolgreich beendet") {
        connectBoardWebSocket(boardId);
        setGameData(null); // Entferne das beendete Spiel
      }
    };

    socket.onerror = (err) => {
      console.error("WebSocket error (game):", err);
      setError("WebSocket-Verbindungsfehler (game).");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed for game.");
    };

    gameSocketRef.current = socket;
  };

  const connectBoardWebSocket = (boardId) => {
    if (boardSocketRef.current) {
      boardSocketRef.current.close();
    }

    const wsUrl = `${WEBSOCKET_URL}/ws/board/${boardId}`;
    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("WebSocket connected for board:", boardId);
      setError(null);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.action === "new_game") {
        fetchGameData(); // Neue Spieldaten abrufen
      }
    };

    socket.onerror = (err) => {
      console.error("WebSocket error (board):", err);
      setError("WebSocket-Verbindunfsfehler (board).");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed for board.");
    };

    boardSocketRef.current = socket;
  };

  useEffect(() => {
    if (!gameId && !boardId) {
      setError("Keine gameId oder boardId angegeben.");
      return;
    }
    fetchGameData();
  }, [gameId, boardId]);

  useEffect(() => {
    if (gameData) {
      connectGameWebSocket(gameData.game_id);
    } else if (boardId) {
      connectBoardWebSocket(boardId);
    }

    return () => {
      if (gameSocketRef.current) {
        gameSocketRef.current.close();
      }
      if (boardSocketRef.current) {
        boardSocketRef.current.close();
      }
    };
  }, [gameData, boardId]);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!gameData) {
    return (
      <div className="text-center text-gray-500">Kein aktives Spiel...</div>
    );
  }

  return (
    <div className="text-black w-full max-w-md  p-4 relative">
      {/* Überschriften */}
      <div className="grid grid-cols-5 items-center border-b-[1px] border-gray-300 bg-slate-600">
        <span className="col-span-3 text-left text-white pl-3">
          {gameData.round} - First to {gameData.legs_to_win}
        </span>
        <span className="text-center text-white border-l-[1px] border-gray-300">
          Legs
        </span>
        <span className="text-center text-white border-l-[1px] border-gray-300">
          Score
        </span>
      </div>

      {/* Teams */}
      {["team1", "team2"].map((teamKey, index) => (
        <div
          key={index}
          className="grid grid-cols-5 items-center border-b-[1px] border-gray-300 relative"
        >
          <div className="col-span-3 text-left text-xl font-extrabold pl-3 bg-slate-50 flex items-center">
            {gameData.teams[teamKey].name}
            {gameData.throw_on_team === teamKey && (
              <span className="w-2 h-2 bg-pink-500 rounded-full ml-2" />
            )}
          </div>
          <div className="text-center text-lg font-bold border-l-[1px] border-gray-300 bg-slate-600 text-white">
            {gameData.teams[teamKey].legs_won}
          </div>
          <div className="text-center text-lg font-bold text-yellow-500 border-l-[1px] border-gray-300 bg-slate-600">
            {gameData.teams[teamKey].score}
          </div>
          {gameData.current_team === teamKey && (
            <div className="absolute right-[-8px] top-0 bottom-0 w-[4px] bg-yellow-500"></div>
          )}
        </div>
      ))}

      {/* Unterschrift mit Logo */}
      <div className="flex items-center justify-between text-lg pl-3 bg-slate-600 text-white">
        <span>FC Freier Grund Darts Gala</span>
        <img
          src="/assets/WestEnergie_w.svg" // Beispiel-Pfad zum SVG
          alt="Sponsor Logo"
          className="h-6 w-auto mr-2"
        />
      </div>
    </div>
  );
};

export default LiveScoreboard;
