import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { BACKEND_URL, WEBSOCKET_URL } from "../config";
import { ClipLoader } from "react-spinners"; // Importiere den Spinner

function TournamentGames() {
  const { eventId } = useParams();
  const location = useLocation();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [selectedBoard, setSelectedBoard] = useState("all");
  const [initialBoardQuery, setInitialBoardQuery] = useState(null);

  useEffect(() => {
    let socket;
    const connectWebSocket = () => {
      const wsUrl = `${WEBSOCKET_URL}/ws/event/${eventId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected to:", wsUrl);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === "reload") {
          fetchEvent();
          fetchGames();
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected. Attempting to reconnect...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [eventId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const boardParam = queryParams.get("board");

    if (boardParam) {
      setSelectedBoard(boardParam);
      setInitialBoardQuery(boardParam); // Merkt sich den initial gesetzten Query-Parameter
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([fetchEvent(), fetchGames()]);
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, location.search]);

  const fetchEvent = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}`);
      const data = await response.json();
      setEvent(data);
      if (data?.status === "Mainround") {
        setSelectedGroup("mainround");
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Events:", error);
    }
  };

  const fetchGames = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/events/${eventId}/games`);
      if (response.ok) {
        const data = await response.json();
        const groupedGames = data.games.reduce((acc, game) => {
          const groupName =
            game.round.stage === 0
              ? game.round.group || "Gruppe unbekannt"
              : "Hauptrunde";
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(game);
          return acc;
        }, {});

        const sortedGroupedGames = Object.keys(groupedGames)
          .sort()
          .reduce((acc, key) => {
            acc[key] = groupedGames[key];
            return acc;
          }, {});

        setGames(sortedGroupedGames);
      } else {
        console.error("Fehler beim Abrufen der Spiele:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Spiele:", error);
    }
  };

  const generateTournament = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/generate_schedule`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        alert("Turnierbaum erfolgreich erstellt!");
        fetchEvent();
        fetchGames();
      } else {
        const error = await response.json();
        alert(`Fehler: ${error.detail}`);
      }
    } catch (error) {
      console.error("Fehler beim Erstellen des Turnierbaums:", error);
    }
  };

  const startMainRound = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/start_main_round`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        alert("Hauptrunde gestartet!");
        fetchEvent();
        fetchGames();
      } else {
        const error = await response.json();
        alert(`Fehler: ${error.detail}`);
      }
    } catch (error) {
      console.error("Fehler beim Starten der Hauptrunde:", error);
    } finally {
      setLoading(false);
    }
  };

  const startTournament = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/start_tournament`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        alert("Turnier gestartet!");
        fetchEvent();
      } else {
        const error = await response.json();
        alert(`Fehler: ${error.detail}`);
      }
    } catch (error) {
      console.error("Fehler beim Starten des Turniers:", error);
    }
  };

  const startNextRound = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/start_next_round`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        alert("Nächste Runde gestartet!");
        fetchEvent();
        fetchGames();
      } else {
        const error = await response.json();
        alert(`Fehler: ${error.detail}`);
      }
    } catch (error) {
      console.error("Fehler beim Starten der nächsten Runde:", error);
    }
  };

  const GameCard = ({ game }) => (
    <div className="flex-col items-center border rounded-lg p-4 bg-gray-100 shadow-md gap-1">
      <div className="flex-1 text-left">
        <span className="font-medium">Spiel {game.id}:</span>{" "}
        <span className={`${game.winner === game.team1 ? "font-bold" : ""}`}>
          {game.team1}
        </span>{" "}
        {game.legscore_team1} vs {game.legscore_team2}{" "}
        <span className={`${game.winner === game.team2 ? "font-bold" : ""}`}>
          {game.team2}
        </span>
      </div>
      {game.round.stage == 0 && (
        <div className="flex-1 text-left">
          <span className="font-medium">Gruppe: {game.round.group}</span>
        </div>
      )}
      <div className="flex-1 text-left">
        <span className="font-medium">Runde:</span> {game.round.description}
      </div>

      <div className="flex-1 text-left">
        <span className="font-medium">Board:</span>{" "}
        {game.board.description || "Nicht zugewiesen"}
      </div>

      <div className="flex-1 text-left">
        <span className="font-medium">Status:</span> {game.status || ""}
      </div>
      {game.winner && (
        <div className="flex-1 text-left">
          <span className="font-medium">Gewinner:</span> {game.winner || ""}
        </div>
      )}

      <div className="flex-1 text-right">
        {(game.status === "bereit" || game.status === "aktiv") && (
          <button
            onClick={() => {
              initialBoardQuery === null
                ? (window.location.href = `/game/${game.id}/gameCalculator`)
                : (window.location.href = `/game/${game.id}/gameCalculator?board=${initialBoardQuery}`);
            }}
            className={`text-white px-4 py-1 rounded ${
              game.status === "bereit" ? "bg-green-500" : "bg-red-500"
            }`}
          >
            Zum Spiel
          </button>
        )}
      </div>
    </div>
  );

  const filteredGames = () => {
    let filtered = Object.values(games)
      .flat()
      .filter(
        (game) =>
          selectedGroup === "all" ||
          (selectedGroup === "mainround" && game.round.stage > 0) ||
          game.round.group === selectedGroup
      );

    if (selectedBoard !== "all") {
      filtered = filtered.filter(
        (game) => game.board.description === selectedBoard
      );
    }

    if (selectedGroup === "all") {
      filtered.sort((a, b) => a.id - b.id);
    }

    return filtered;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#36D7B7"} />
      </div>
    );
  }
  return (
    <div className="p-4">
      {event?.status === "new" ? (
        <h2 className="text-2xl font-semibold mb-4">
          Turnier noch nicht freigegeben
        </h2>
      ) : (
        <>
          {initialBoardQuery === null && (
            <div className="mb-4">
              <div className="mb-4">
                {event?.status === "released" && (
                  <button
                    onClick={generateTournament}
                    className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                  >
                    Turnierbaum erstellen
                  </button>
                )}
                {event?.status === "group_finished" && (
                  <button
                    onClick={startMainRound}
                    className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                  >
                    Hauptrunde starten
                  </button>
                )}
                {event?.status === "ready" && (
                  <button
                    onClick={startTournament}
                    className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                  >
                    Turnier starten
                  </button>
                )}
                {event?.status === "mainround_ready" && (
                  <button
                    onClick={startNextRound}
                    className="bg-blue-500 text-white px-4 py-2 mr-2 rounded w-40"
                  >
                    Nächste Runde starten
                  </button>
                )}
              </div>

              <label htmlFor="groupFilter" className="block font-semibold">
                Filtern nach Gruppe/Runde:
              </label>
              <select
                id="groupFilter"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                className="px-3 py-2 border rounded w-full text-sm md:text-base"
              >
                <option value="all">Alle</option>
                <option value="mainround">Hauptrunde</option>
                {Object.keys(games)
                  .filter((group) => group !== "Hauptrunde")
                  .map((group) => (
                    <option key={group} value={group}>
                      {group}
                    </option>
                  ))}
              </select>

              <label htmlFor="boardFilter" className="block font-semibold mt-2">
                Filtern nach Board:
              </label>
              <select
                id="boardFilter"
                value={selectedBoard}
                onChange={(e) => setSelectedBoard(e.target.value)}
                className="px-3 py-2 border rounded w-full text-sm md:text-base"
              >
                <option value="all">Alle</option>
                {[
                  ...new Set(
                    Object.values(games)
                      .flat()
                      .map((game) => game.board.description)
                  ),
                ]
                  .filter((board) => board)
                  .map((board) => (
                    <option key={board} value={board}>
                      {board}
                    </option>
                  ))}
              </select>
            </div>
          )}

          <div className="space-y-4">
            {filteredGames().length > 0 ? (
              filteredGames().map((game) => (
                <GameCard key={`game-${game.id}`} game={game} />
              ))
            ) : (
              <p>Keine Spiele verfügbar</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TournamentGames;
