import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../config";

Modal.setAppElement("#root");

const BoardSelector = ({ eventId, isOpen, onClose }) => {
  const [boards, setBoards] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) return;

    const fetchBoards = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/events/${eventId}/boards`);
        if (!response.ok) throw new Error("Fehler beim Abrufen der Boards");
        const data = await response.json();
        setBoards(data);
      } catch (err) {
        console.error(err);
        setError("Fehler beim Abrufen der Boards.");
      }
    };

    fetchBoards();
  }, [eventId, isOpen]);

  const handleBoardClick = (boardId) => {
    navigate(`/live/scoreboard?boardId=${boardId}`); // Navigiere zur Scoreboard-Komponente
    onClose(); // Schließe das Pop-Up
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h3 className="text-xl font-semibold mb-4 text-gray-800">
        Boards auswählen
      </h3>
      {error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : boards.length === 0 ? (
        <p className="text-gray-500">Keine Boards gefunden.</p>
      ) : (
        <ul className="space-y-4">
          {boards.map((board) => (
            <li
              key={board.id}
              className="cursor-pointer bg-gray-200 hover:bg-gray-300 p-4 rounded shadow"
              onClick={() => handleBoardClick(board.id)}
            >
              <div className="text-lg font-bold">{board.description}</div>
              <div className="text-sm text-gray-600">
                Status: {board.status}
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        onClick={onClose}
        className="mt-4 text-red-500 hover:underline text-center w-full"
      >
        Abbrechen
      </button>
    </Modal>
  );
};

export default BoardSelector;
