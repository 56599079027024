import React, { useState } from "react";
import CustomKeyboard from "./CustomKeyboard";

function InputSection({
  isMobile,
  scoreInput,
  setScoreInput,
  scoreInputSingle,
  setScoreInputSingle,
  handleScoreSubmit,
  undo_score,
  manualPlayerSwitch,
  manualTeamSwitch,
  useSingleInput,
  setUseSingleInput,
  isErrorInput,
  setIsErrorInput,
  handleErrorInput,
  currentScore,
}) {
  const [activeInputIndex, setActiveInputIndex] = useState(null); // Das aktive Input-Feld

  // Diese Funktion wird aufgerufen, wenn eine Zahl auf der CustomKeyboard gedrückt wird
  const handleNumberClickMobile = (number) => {
    if (useSingleInput && activeInputIndex !== null) {
      // Einzel-Input-Modus
      const newScores = [...scoreInputSingle];
      const currentScore = newScores[activeInputIndex] || 0;

      // Kombiniere die neue Zahl mit der bestehenden, wenn der Wert < 10 ist (maximal 2 Stellen)
      const newScore =
        currentScore < 10 ? currentScore * 10 + parseInt(number) : currentScore;

      // Wenn der Wert größer als 60 ist, setze ihn auf 0 und setze isError auf true
      if (newScore > 60) {
        handleErrorInput();
        newScores[activeInputIndex] = "";
      } else {
        newScores[activeInputIndex] = newScore;
      }
      setScoreInputSingle(newScores);

      // Berechne den Gesamtwert und setze ihn auf setScoreInput
      const totalScore = newScores.reduce((acc, curr) => acc + (curr || 0), 0); // Nullwerte werden als 0 betrachtet
      if ((newScore > 9) & (activeInputIndex < 2) & (newScore <= 60)) {
        setActiveInputIndex(activeInputIndex + 1);
      }
      setScoreInput(totalScore); // Setzt den Gesamtwert
    } else {
      // Gesamtscore-Modus
      const currentScore = scoreInput || 0;
      // Füge die neue Zahl hinzu, aber maximal 3 Stellen
      const newScore =
        currentScore.toString().length < 3
          ? currentScore * 10 + parseInt(number)
          : currentScore;

      // Wenn der Wert größer als 180 ist, setze ihn auf 0 und setze isError auf true
      if (newScore > 180) {
        handleErrorInput();
        setScoreInput("");
      } else {
        setScoreInput(newScore);
      }
    }
  };

  // Lösche die letzte Ziffer des aktiven Feldes
  const handleDeleteMobile = () => {
    if (useSingleInput && activeInputIndex !== null) {
      const updatedScores = [...scoreInputSingle];

      // Wenn das Feld nicht null ist und einen Wert hat, entferne die letzte Ziffer
      if (
        updatedScores[activeInputIndex] !== null &&
        updatedScores[activeInputIndex] > 0
      ) {
        const stringScore = updatedScores[activeInputIndex].toString();
        const newScore = stringScore.slice(0, -1); // Entferne die letzte Ziffer

        updatedScores[activeInputIndex] = newScore ? parseInt(newScore) : null; // Setze auf null, wenn alles gelöscht ist
        setScoreInputSingle(updatedScores);
      }

      // Berechne den Gesamtscore
      const totalScore = updatedScores.reduce(
        (acc, curr) => acc + (curr || 0),
        0
      );
      setScoreInput(totalScore);
    } else if (!useSingleInput) {
      // Falls Gesamtscore-Modus (nicht SingleInput-Modus)
      if (scoreInput !== null) {
        const stringScore = scoreInput.toString();
        const updatedScore = stringScore.slice(0, -1); // Entferne die letzte Ziffer

        setScoreInput(updatedScore ? parseInt(updatedScore) : null); // Setze auf null, wenn alles gelöscht ist
      }
    }
  };

  // Leeren der Felder bei Moduswechsel
  const handleModeSwitch = () => {
    setUseSingleInput(!useSingleInput);

    // Setze alle Felder auf null (leeren)
    setScoreInputSingle([null, null, null]);
    setScoreInput(null);
    setActiveInputIndex(0);
  };

  const handleOkClick = () => {
    if (useSingleInput) {
      // Im SingleInput-Modus
      const allFieldsValid = scoreInputSingle.every(
        (score) => score !== null && score >= 0 && score <= 60
      );

      const legFinished = currentScore - scoreInput === 0;

      if (!allFieldsValid && !legFinished) {
        alert(
          "Bitte fülle alle Felder aus und gib Werte zwischen 0 und 60 ein."
        );
        return; // Beende die Funktion, wenn die Validierung fehlschlägt
      }
    } else {
      // Im Gesamtscore-Modus
      if (scoreInput === null || scoreInput < 0 || scoreInput > 180) {
        alert("Bitte gib einen Wert zwischen 0 und 180 im Gesamtscore ein.");
        return; // Beende die Funktion, wenn die Validierung fehlschlägt
      }
    }

    // Wenn alles in Ordnung ist, kannst du die gewünschte Aktion ausführen
    handleScoreSubmit();
    setScoreInputSingle([null, null, null]);
    setActiveInputIndex(0);
  };

  const handleUndoClick = () => {
    if (useSingleInput) {
      const updatedScores = [...scoreInputSingle];

      // Wenn das aktuelle Feld leer ist oder nicht leer, verschiebe den Fokus
      if (activeInputIndex !== null) {
        if (updatedScores[activeInputIndex] !== null) {
          updatedScores[activeInputIndex] = null;
          setScoreInputSingle(updatedScores);

          // Berechne den neuen Gesamtscore
          const totalScore = updatedScores.reduce(
            (acc, curr) => acc + (curr || 0),
            0
          );
          setScoreInput(totalScore);
        }

        // Setze den Fokus auf das vorherige Feld, falls vorhanden
        if (activeInputIndex >= 0) {
          setActiveInputIndex(activeInputIndex - 1);
        }
        // Wenn das erste Feld erreicht ist, überprüfe, ob alle Felder leer sind
        const allFieldsEmpty = updatedScores.every(
          (score) => score === null || score === 0
        );

        if (activeInputIndex === -1 && allFieldsEmpty) {
          undo_score(); // Rufe undo_score nur beim zweiten Druck auf, wenn alles leer ist
          setActiveInputIndex(0);
        }

        return; // Beende hier, da der Undo für das aktuelle Feld erledigt wurde
      }
    } else {
      // Standard-Undo für Gesamtscore-Modus
      undo_score();
      setScoreInput("");
    }
  };

  const handleManualPlayerSwitch = () => {
    // Lösche alle SingleInput-Scores
    setScoreInputSingle([null, null, null]);
    setScoreInput(""); // Setze den Gesamtscore auf 0
    setActiveInputIndex(0); // Setze den Fokus zurück auf das erste Feld
    manualPlayerSwitch();
  };

  const handleManualTeamSwitch = () => {
    // Lösche alle SingleInput-Scores
    setScoreInputSingle([null, null, null]);
    setScoreInput(""); // Setze den Gesamtscore auf 0
    setActiveInputIndex(0); // Setze den Fokus zurück auf das erste Feld

    manualTeamSwitch();
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center space-y-4">
        {/* Das Hauptfeld, das immer den Gesamtscore anzeigt */}
        <input
          type="number"
          value={scoreInput ?? ""}
          placeholder="Gesamtscore"
          className={`border rounded-lg p-2 w-full sm:flex-1 ${
            isErrorInput && !useSingleInput ? "bg-red-500" : "bg-white"
          } disabled:text-black disabled:opacity-100 readonly:text-black readonly:opacity-100 ${
            !useSingleInput && "border-blue-500"
          } `}
          readOnly={isMobile} // Setze readOnly nur auf true, wenn isMobile true
          disabled={isMobile} // Deaktiviere nur, wenn isMobile true
          onChange={(e) => !isMobile && setScoreInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleOkClick(); // Ruft die Funktion auf, wenn die Enter-Taste gedrückt wird
            }
          }}
        />

        {useSingleInput && (
          <div className="flex flex-row my-2 gap-1">
            <input
              type="text"
              value={scoreInputSingle[0] !== null ? scoreInputSingle[0] : ""}
              readOnly // Da die Eingabe über die CustomKeyboard erfolgt
              onClick={() => setActiveInputIndex(0)}
              placeholder="Score1"
              className={`border rounded-lg p-2 w-full sm:flex-1 ${
                activeInputIndex === 0 && isErrorInput ? "bg-red-500" : ""
              } ${activeInputIndex === 0 ? "border-blue-500" : ""}`}
            />
            <input
              type="text"
              value={scoreInputSingle[1] !== null ? scoreInputSingle[1] : ""}
              readOnly // Da die Eingabe über die CustomKeyboard erfolgt
              onClick={() => setActiveInputIndex(1)}
              placeholder="Score2"
              className={`border rounded-lg p-2 w-full sm:flex-1 ${
                activeInputIndex === 1 && isErrorInput ? "bg-red-500" : ""
              } ${activeInputIndex === 1 ? "border-blue-500" : ""}`}
            />
            <input
              type="text"
              value={scoreInputSingle[2] !== null ? scoreInputSingle[2] : ""}
              readOnly // Da die Eingabe über die CustomKeyboard erfolgt
              onClick={() => setActiveInputIndex(2)}
              placeholder="Score3"
              className={`border rounded-lg p-2 w-full sm:flex-1 ${
                activeInputIndex === 2 && isErrorInput ? "bg-red-500" : ""
              } ${activeInputIndex === 2 ? "border-blue-500" : ""}`}
            />
          </div>
        )}

        {isMobile ? (
          <CustomKeyboard
            onNumberClick={handleNumberClickMobile} // Verarbeitet die Zahlenklicks
            onOkClick={handleOkClick} // Hier die angepasste Validierung
            onUndoClick={handleUndoClick}
            onDeleteClick={handleDeleteMobile} // Verarbeitet das Löschen
            onPlayerSwitchClick={handleManualPlayerSwitch}
            onTeamSwitchClick={handleManualTeamSwitch}
            onInputModeSwitch={handleModeSwitch}
            useSingleInput={useSingleInput}
          />
        ) : (
          <div className="flex flex-row gap-2">
            <button
              onClick={handleOkClick}
              className="bg-green-500 text-white w-full sm:w-auto px-4 py-2 rounded"
            >
              Ok
            </button>
            <button
              onClick={undo_score}
              className="bg-green-500 text-white w-full sm:w-auto px-4 py-2 rounded"
            >
              Undo
            </button>
          </div>
        )}
        {/* CustomKeyboard Component */}
      </div>
    </div>
  );
}

export default InputSection;
